const WavesDown = () => {
  return (
    <div>
      <svg
        width="100%"
        height="100%"
        id="svg"
        viewBox="0 0 1440 400"
        xmlns="http://www.w3.org/2000/svg"
        className="transition duration-300 ease-in-out delay-150 block lg:hidden"
      >
        <path
          d="M 0,400 C 0,400 0,100 0,100 C 117.76076555023923,94.75598086124401 235.52153110047846,89.51196172248804 321,96 C 406.47846889952154,102.48803827751196 459.67464114832535,120.70813397129186 537,122 C 614.3253588516747,123.29186602870814 715.77990430622,107.65550239234449 836,102 C 956.22009569378,96.34449760765551 1095.2057416267942,100.66985645933015 1199,102 C 1302.7942583732058,103.33014354066985 1371.3971291866028,101.66507177033492 1440,100 C 1440,100 1440,400 1440,400 Z"
          stroke="none"
          strokeWidth="0"
          fill="#fff7e666"
          className="transition-all duration-300 ease-in-out delay-150 path-0"
          transform="rotate(-180 720 200)"
        ></path>
        <path
          d="M 0,400 C 0,400 0,200 0,200 C 114.43062200956936,218.13397129186603 228.86124401913872,236.26794258373204 323,236 C 417.1387559808613,235.73205741626796 490.98564593301444,217.06220095693783 563,203 C 635.0143540669856,188.93779904306217 705.1961722488037,179.4832535885167 817,172 C 928.8038277511963,164.5167464114833 1082.2296650717706,159.00478468899524 1193,164 C 1303.7703349282294,168.99521531100476 1371.8851674641146,184.49760765550238 1440,200 C 1440,200 1440,400 1440,400 Z"
          stroke="none"
          strokeWidth="0"
          fill="#fff7e688"
          className="transition-all duration-300 ease-in-out delay-150 path-1"
          transform="rotate(-180 720 200)"
        ></path>
        <path
          d="M 0,400 C 0,400 0,300 0,300 C 83.61722488038276,298.6411483253588 167.23444976076553,297.2822966507177 249,307 C 330.76555023923447,316.7177033492823 410.67942583732054,337.5119617224881 527,335 C 643.3205741626795,332.4880382775119 796.0478468899523,306.6698564593301 915,294 C 1033.9521531100477,281.3301435406699 1119.1291866028707,281.8086124401914 1201,285 C 1282.8708133971293,288.1913875598086 1361.4354066985647,294.0956937799043 1440,300 C 1440,300 1440,400 1440,400 Z"
          stroke="none"
          strokeWidth="0"
          fill="#fff7e6ff"
          className="transition-all duration-300 ease-in-out delay-150 path-2"
          transform="rotate(-180 720 200)"
        ></path>
      </svg>
      <svg
        id="visual"
        viewBox="0 0 960 100"
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        className="hidden lg:block"
      >
        <path
          d="M0 60L22.8 58.5C45.7 57 91.3 54 137 56.2C182.7 58.3 228.3 65.7 274 69.8C319.7 74 365.3 75 411.2 75.2C457 75.3 503 74.7 548.8 73.3C594.7 72 640.3 70 686 70.7C731.7 71.3 777.3 74.7 823 74.8C868.7 75 914.3 72 937.2 70.5L960 69L960 0L937.2 0C914.3 0 868.7 0 823 0C777.3 0 731.7 0 686 0C640.3 0 594.7 0 548.8 0C503 0 457 0 411.2 0C365.3 0 319.7 0 274 0C228.3 0 182.7 0 137 0C91.3 0 45.7 0 22.8 0L0 0Z"
          fill="#ffffff"
        ></path>
        <path
          d="M0 68L22.8 64.5C45.7 61 91.3 54 137 52.3C182.7 50.7 228.3 54.3 274 55.8C319.7 57.3 365.3 56.7 411.2 55.5C457 54.3 503 52.7 548.8 54.2C594.7 55.7 640.3 60.3 686 63.3C731.7 66.3 777.3 67.7 823 65.2C868.7 62.7 914.3 56.3 937.2 53.2L960 50L960 0L937.2 0C914.3 0 868.7 0 823 0C777.3 0 731.7 0 686 0C640.3 0 594.7 0 548.8 0C503 0 457 0 411.2 0C365.3 0 319.7 0 274 0C228.3 0 182.7 0 137 0C91.3 0 45.7 0 22.8 0L0 0Z"
          fill="#fffdfa"
        ></path>
        <path
          d="M0 41L22.8 42.5C45.7 44 91.3 47 137 46.5C182.7 46 228.3 42 274 39.7C319.7 37.3 365.3 36.7 411.2 36.3C457 36 503 36 548.8 38C594.7 40 640.3 44 686 46.7C731.7 49.3 777.3 50.7 823 50.5C868.7 50.3 914.3 48.7 937.2 47.8L960 47L960 0L937.2 0C914.3 0 868.7 0 823 0C777.3 0 731.7 0 686 0C640.3 0 594.7 0 548.8 0C503 0 457 0 411.2 0C365.3 0 319.7 0 274 0C228.3 0 182.7 0 137 0C91.3 0 45.7 0 22.8 0L0 0Z"
          fill="#fffcf5"
        ></path>
        <path
          d="M0 37L22.8 37.5C45.7 38 91.3 39 137 40C182.7 41 228.3 42 274 39.7C319.7 37.3 365.3 31.7 411.2 31.8C457 32 503 38 548.8 38C594.7 38 640.3 32 686 30.7C731.7 29.3 777.3 32.7 823 34C868.7 35.3 914.3 34.7 937.2 34.3L960 34L960 0L937.2 0C914.3 0 868.7 0 823 0C777.3 0 731.7 0 686 0C640.3 0 594.7 0 548.8 0C503 0 457 0 411.2 0C365.3 0 319.7 0 274 0C228.3 0 182.7 0 137 0C91.3 0 45.7 0 22.8 0L0 0Z"
          fill="#fffaf0"
        ></path>
        <path
          d="M0 28L22.8 27C45.7 26 91.3 24 137 21.8C182.7 19.7 228.3 17.3 274 18C319.7 18.7 365.3 22.3 411.2 22.7C457 23 503 20 548.8 19.3C594.7 18.7 640.3 20.3 686 22.7C731.7 25 777.3 28 823 27C868.7 26 914.3 21 937.2 18.5L960 16L960 0L937.2 0C914.3 0 868.7 0 823 0C777.3 0 731.7 0 686 0C640.3 0 594.7 0 548.8 0C503 0 457 0 411.2 0C365.3 0 319.7 0 274 0C228.3 0 182.7 0 137 0C91.3 0 45.7 0 22.8 0L0 0Z"
          fill="#fff9eb"
        ></path>
        <path
          d="M0 20L22.8 17.7C45.7 15.3 91.3 10.7 137 8.5C182.7 6.3 228.3 6.7 274 8.2C319.7 9.7 365.3 12.3 411.2 13.3C457 14.3 503 13.7 548.8 12.3C594.7 11 640.3 9 686 7.8C731.7 6.7 777.3 6.3 823 8C868.7 9.7 914.3 13.3 937.2 15.2L960 17L960 0L937.2 0C914.3 0 868.7 0 823 0C777.3 0 731.7 0 686 0C640.3 0 594.7 0 548.8 0C503 0 457 0 411.2 0C365.3 0 319.7 0 274 0C228.3 0 182.7 0 137 0C91.3 0 45.7 0 22.8 0L0 0Z"
          fill="#fff7e6"
        ></path>
      </svg>
    </div>
  );
};

export default WavesDown;
