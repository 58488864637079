import * as React from "react";

function CloseICon(props) {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      {...props}
    >
      <path
        fill="none"
        stroke="#000"
        strokeWidth={2}
        d="M3,3 L21,21 M3,21 L21,3"
      />
    </svg>
  );
}

export default CloseICon;
